<template>
  <v-container app fluid grid-list-md>
    <v-layout align-space-between justify-space-around row wrap grey darken-1>
      <v-flex d-flex xs12 sm6 md3 pt-3 pb-3>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <!-- <v-img :src="images.other" height="200px" /> -->
            <div style="text-align:center">
              <div class="display-1 purple">{{ $t('services.header4') }}</div>
                <div style="height:30px"></div>
              <p class="title white--text">{{ $t('services.sub41') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub411') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub412') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub413') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub414') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub42') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub421') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub422') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub423') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub424') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub43') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub431') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub432') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub433') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub434') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub44') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub441') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub442') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub443') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub444') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub45') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub451') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub452') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub453') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub454') }}</p>
                <div style="height:20px"></div>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0;
}
.v-content {
  padding: 0 !important;
  margin: 0 !important;
}
</style>