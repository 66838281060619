<template>
  <!-- <v-container fluid grid-list-md> -->
    <content>
    <v-layout align-space-between justify-space-around grey darken-1 row>
      <v-flex d-flex xs12 sm6 md8>
        <v-card flat class="v-card-profile" color="grey darken-1">
          <div class="text-xs-center">
            <v-card-text>
              <h1 class="hidden-sm-and-down title card-title white--text">{{ $t('about.vision') }}</h1>
              <h1 class="hidden-md-and-up body-1 card-title white--text">{{ $t('about.vision') }}</h1>
            </v-card-text>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout align-space-between justify-space-around grey darken-1 row>
      <v-flex d-flex xs12 sm6 md8>
        <v-card flat class="v-card-profile" color="grey darken-1">
          <div class="text-xs-center">
            <v-card-text>
              <h1 class="hidden-sm-and-down title card-title white--text">{{ $t('about.mission') }}</h1>
              <h1 class="hidden-md-and-up body-1 card-title white--text">{{ $t('about.mission') }}</h1>
            </v-card-text>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout align-space-between justify-space-around grey darken-1 row>
      <v-flex d-flex xs12 sm6 md8>
        <v-card flat class="v-card-profile" color="grey darken-1">
          <div class="text-xs-center">
            <v-card-text>
              <h1 class="hidden-sm-and-down title card-title white--text">{{ $t('about.regions') }}</h1>
              <h1 class="hidden-md-and-up body-1 card-title white--text">{{ $t('about.regions') }}</h1>
            </v-card-text>
          </div>
        </v-card>
      </v-flex>
    </v-layout>

<!-- <form onSubmit="{handleSubmit}">
  <label>
    e-mail:
    <br />
    <input type="email" name="email" value="{email}" onChange="{handleEmailChange}" />
  </label>
  <br />
  <label>
    Message
    <br />
    <textarea type="text" name="message" value="{message}" onChange="{handleMessageChange}" />
  </label>
  <br />
  <input type="submit" value="submit" />
</form> -->
  <!-- </v-container> -->
    </content>
</template>

<script>
export default {
    name: 'ContactForm',
    data: function () {
        return {
            contactFormData: {
                name: '',
                email: '',
                message: '',
            },
            success: false,
            error: false,
        }
    },
    methods: {
        // sendMail: function () {
        //     // in a real app, it would be better if the URL is extracted as a env variable
        //     // const url = ' https://localhost:5001/vue-contact-form-3c668/us-central1/sendEmailCF';
        //     // const url = 'https://ibeck-me.firebaseapp.com/sendEmailCF';
        //     const url = 'https://us-central1-ibeck-me.cloudfunctions.net/sendEmailCF';
        //     const {name, email, message} = this.contactFormData;
        //     const payload = {name, email, message};
        //     fetch(url, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(payload)
        //     })
        //         .then(() => {
        //             this.success = true;
        //             this.resetForm();
        //         })
        //         .catch(() => {
        //             this.error = true;
        //         })
        // },
        // resetForm: function () {
        //     this.contactFormData = {
        //         name: '',
        //         email: '',
        //         message: '',
        //     };
        // },
    },
}
</script>

<style scoped>
.contact-form {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 360px;
}
.form-group {
    padding: 10px;
}
.form-control {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
input[type=text].form-control {
    margin: 8px 0;
    display: inline-block;
}
textarea.form-control {
    resize: none;
}
.btn {
    cursor: pointer;
    padding: 8px 10px;
    outline: none;
    border: none;
    background: #3be249;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    text-align: center;
}
.alert {
    padding:0 10px;
}
.alert-success {
    color:#3be249;
}
.alert-error {
    color: #ff2121;
}
</style>

