<template>
  <v-content>
    <section id="hero">
      <v-row no-gutters>
        <div style="height:60px"></div>
        <v-img :src="images.about" aspect-ratio="2.0" max-height="625" min-height="225">
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span
                    class="font-weight-dark"
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'
                    ]"
                  >
                    ABOUT
                  </span>
                  <br />
                </v-col>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>
  </v-content>
</template>

<script>
export default {
  name: 'HomeImage',
  data() {
    return {
  images: {
    about: require('@/assets/it-about.jpg'),
  }
    }
}
}
</script>

<style lang="scss" scoped>
// .home-hero {

//   background-image: linear-gradient(
//       -225deg,
//       rgba(255, 255, 255, 0.4) 0%,
//       rgba(0, 36, 61, 0.4) 50%
//     ),
//     url(../../assets/it-consultant.jpg);
//   background-size: contain;
//   background-position-y: 60px;
//   // background-repeat: no-repeat;
//   background-attachment: fixed;
//   height: 80%;
//   opacity: 0.99;
// }
</style>
