<template>
  <v-app id="home">
  <v-content>
    <top-image />
    <section id="services">
      <what-wedo />
    </section>
    <web-footer />
  </v-content>
  </v-app>
</template>

<script>
import TopImage from '@/views/services/TopImage.vue'
import WhatWedo from '@/views/services/Strategy.vue'
import WebFooter from '@/components/webFooter.vue'

export default {
  components: {
    TopImage,
    WhatWedo,
    WebFooter
  }
}
</script>
<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0;
}
.v-content {
  padding: 0 !important;
}
</style>
