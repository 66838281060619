<template>
  <v-content>
    <top-image />
    <vision-mission />
    <web-footer />
  </v-content>
</template>

<script>
import TopImage from './about/TopImage.vue'
import VisionMission from './about/VisionMission.vue'
import WebFooter from '../components/webFooter.vue'

export default {
  components: {
    TopImage,
    VisionMission,
    WebFooter
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0;
}
.v-content {
  padding: 0 !important;
}
</style>
