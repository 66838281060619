<template>
  <v-container app fluid grid-list-md>
    <v-layout align-space-between justify-space-around row wrap grey darken-1>
      <v-flex d-flex xs12 sm6 md3 pt-3 pb-3>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <!-- <v-img :src="images.education" height="200px" /> -->
            <div style="text-align:center">
              <div class="display-1 purple">{{ $t('services.header1') }}</div>
                <div style="height:30px"></div>

              <p class="title white--text">{{ $t('services.sub11') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub111') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub112') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub113') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub114') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub12') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub121') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub122') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub123') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub124') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub13') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub131') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub132') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub133') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub134') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub14') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub141') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub142') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub143') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub144') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub15') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub151') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub152') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub153') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub154') }}</p>
                <div style="height:20px"></div>
            </div>
          </div>
        </v-card>
      </v-flex>
     </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0;
}
.v-content {
  padding: 0 !important;
  margin: 0 !important;
}
</style>