<template>
  <!-- <v-app id="signin"> -->
  <v-content>
    <div style="height:60px"></div>
    <div class="page-wrapper">
      <!-- Loader -->
      <div v-show="user === undefined" data-test="loader">
        Authenticating...
      </div>
      <!-- Offline instruction -->
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      ></loading>
      <div v-show="!networkOnLine" data-test="offline-instruction">
        Please check your connection, login feature is not available offline
      </div>

      <div
        class="col2"
        :class="{ 'signup-form': !showSigninForm && !showForgotPassword }"
      >
        <form v-if="showSigninForm" @submit.prevent>
          <!-- <v-card class="elevation-20 rounded-card"> -->
          <v-flex
            xs12
            sm8
            md6
            lg6
            xl6
            offset-sm2
            offset-md3
            grey
            darken-1
            class="elevation-10 rounded-card"
          >
            <v-card-title class="purple">
              <h4 style="color:white">Customer SIGN IN</h4>
              <v-spacer></v-spacer>
              <h4>v2.1</h4>
            </v-card-title>
            <div style="height:10px"></div>
            <v-flex xs10 offset-xs1>
              <v-text-field
                id="email"
                v-model.trim="signinForm.email"
                name="email"
                label="EMAIL"
                :rules="[rules.required, rules.email]"
                type="email"
                single-line
                required
                outline
              ></v-text-field>
            </v-flex>
            <v-flex xs10 offset-xs1>
              <v-text-field
                id="password"
                v-model.trim="signinForm.password"
                name="password"
                label="PASSWORD"
                required
                single-line
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                class="input-group--focused"
                outline
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-flex>
            <div class="text-xs-center">
              <v-btn
                round
                dark
                type="submit"
                :loading="loading2"
                :disabled="loading2"
                color="purple"
                @click.prevent="signinEmail"
              >
                email Sign In
                <v-icon right dark>vpn_key</v-icon>
                <span slot="loader" class="custom-loader">
                  <v-icon light>cached</v-icon>
                </span>
              </v-btn>
              <div class="extras">
                <div style="height:10px"></div>
                <a @click="togglePasswordReset">Forgot Password</a>
                <div style="height:10px"></div>
                <a @click="toggleForm">Create an Account</a>
              </div>
              <div style="height:10px"></div>
              <div class="text-xs-center">
                <div style="height:10px"></div>
                <v-divider></v-divider>
                <h5>OR</h5>
                <div style="height:5px"></div>
                <v-btn
                  round
                  color="purple"
                  dark
                  :disabled="loading1"
                  :loading="loading1"
                  @click.prevent="signinFB"
                >
                  Facebook Sign In
                  <v-icon right dark>vpn_key</v-icon>
                  <span slot="loader" class="custom-loader">
                    <v-icon light>cached</v-icon>
                  </span>
                </v-btn>
                <div style="height:20px"></div>
              </div>
            </div>
          </v-flex>
          <!-- </v-card> -->
        </form>

        <form v-if="!showSigninForm && !showForgotPassword" @submit.prevent>
          <!-- <v-card class="elevation-20 rounded-card"> -->
          <v-flex
            xs12
            sm8
            md8
            offset-sm2
            offset-md2
            class="elevation-20 rounded-card"
          >
            <v-card-title class="purple">
              <h4 style="color:white">EcoPledge SIGN UP</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-flex xs10 offset-xs1 mt-2>
              <v-text-field
                v-model.trim="signupForm.name"
                name="name"
                label="USER NAME"
                outline
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs10 offset-xs1>
              <v-text-field
                v-model.trim="signupForm.email"
                name="email"
                label="EMAIL"
                outline
                class="input-group--focused"
                type="email"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs10 offset-xs1>
              <v-text-field
                id="password"
                v-model.trim="signupForm.password"
                name="password"
                label="Password"
                required
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                class="input-group--focused"
                outline
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-flex>
            <v-flex xs10 offset-xs1>
              <v-text-field
                id="password2"
                v-model.trim="signupForm.password2"
                name="password"
                label="Repeat Password"
                required
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                class="input-group--focused"
                outline
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-flex>
            <div class="text-xs-center">
              <div class="extras">
                <div style="height:10px"></div>
                <a @click.stop="terms = true">Terms of Use</a>
                <div style="height:10px"></div>
              </div>
              <v-dialog v-model="terms" max-width="290">
                <v-card>
                  <v-card-title class="headline">TERMS</v-card-title>
                  <v-card-text>{{ $t('myMe.termsHeader') }}</v-card-text>
                  <v-card-text>{{ $t('myMe.terms01') }}</v-card-text>
                  <v-card-text>{{ $t('myMe.terms02') }}</v-card-text>
                  <v-card-text>{{ $t('myMe.terms03') }}</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      flat="flat"
                      @click="terms = false"
                      >Accept</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-btn
                round
                color="purple"
                dark
                :disabled="loading1"
                :loading="loading1"
                @click="signupEmail"
              >
                CREATE
                <v-icon right dark>lock_open</v-icon>
                <span slot="loader" class="custom-loader">
                  <v-icon light>cached</v-icon>
                </span>
              </v-btn>
              <div style="height:20px"></div>
              <div class="extras">
                <a @click="toggleForm">Back to SIGN IN</a>
              </div>
              <div style="height:20px"></div>
            </div>
          </v-flex>
          <!-- </v-card> -->
        </form>

        <form v-if="showForgotPassword" class="password-reset" @submit.prevent>
          <v-flex
            xs12
            sm8
            md8
            offset-sm2
            offset-md2
            class="elevation-20 rounded-card"
          >
            <v-card-title class="purple">
              <h4 style="color:white">Reset Password</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <div v-if="!passwordResetSuccess">
              <div style="height:20px"></div>
              <div class="text-xs-center">
                <p>We will send you an email to reset your password</p>
              </div>
              <v-layout row wrap>
                <v-flex xs10 offset-xs1 pt-0 pb-0 mt-0 mb-0>
                  <v-text-field
                    id="email"
                    v-model.trim="passwordForm.email"
                    name="email"
                    label="EMAIL"
                    :rules="[rules.required, rules.email]"
                    type="email"
                    single-line
                    outline
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <div class="text-xs-center">
                <v-btn
                  round
                  color="purple"
                  dark
                  :disabled="loading1"
                  :loading="loading1"
                  @click="resetPassword"
                >
                  RESET
                  <v-icon right dark>cached</v-icon>
                  <span slot="loader" class="custom-loader">
                    <v-icon light>cached</v-icon>
                  </span>
                </v-btn>
                <div style="height:20px"></div>
                <div class="extras">
                  <a @click="togglePasswordReset">Back to Sign In</a>
                </div>
                <div style="height:20px"></div>
              </div>
            </div>
            <div v-else>
              <div class="text-xs-center">
                <div class="text-xs-center">
                  <p>Email Sent</p>
                  <p>check your email for a link to reset your password</p>
                </div>
                <div style="height:20px"></div>
                <div class="extras">
                  <a @click="togglePasswordReset">Back to Sign In</a>
                </div>
                <div style="height:20px"></div>
              </div>
            </div>
            <!-- </v-card> -->
          </v-flex>
        </form>
      </div>

      <p v-if="signinError">{{ signinError }}</p>
      <!-- Auth UI -->
      <!-- <div
      v-show="user !== undefined && !user && networkOnLine"
      data-test="signin-btn"
      class="signin-btn"
      @click="signinFB"
      >Signin with facebook</div>-->
      <!-- <div
      v-show="user !== undefined && !user && networkOnLine"
      data-test="signin-btn"
      class="signin-btn"
      @click="signinGoogle"
    >
      Signin with google
      </div>-->
    </div>
  </v-content>
  <!-- </v-app> -->
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
import { desktop as isDekstop } from 'is_js'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },
  data: () => ({
    signinError: null,
    termsCb: false,
    terms: false,
    loader: null,
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,

    isLoading: false,
    fullPage: true,

    showPassword: false,
    performingRequest: false,
    showForgotPassword: false,
    passwordResetSuccess: false,
    errorMsg: '',

    showSigninForm: true,
    signinForm: {
      email: '',
      password: ''
    },
    signupForm: {
      name: '',
      title: '',
      email: '',
      password: '',
      password2: ''
    },
    passwordForm: {
      email: ''
    },
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 20 || 'Max 20 characters',
      min: v => v.length >= 6 || 'Min 6 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
      emailMatch: () => 'The email and password you entered dont match'
    }
  }),
  head: function() {
    return {
      title: {
        inner: 'Signin'
      },
      meta: [
        {
          name: 'description',
          content: `Sign in or sign up to ${this.appTitle}`,
          id: 'desc'
        }
      ]
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle']),
    comparePasswords() {
      return this.password !== this.confirmPassword
        ? 'Passwords do not match'
        : ''
    }
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? '/myhome'
            : this.$route.query.redirectUrl
          this.$router.push(redirectUrl)
        }
      },
      immediate: true
    }
  },
  created() {
    console.log('in signin created')
    firebase.auth().onAuthStateChanged(userAuth => {
      if (userAuth) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(tokenResult => {
            console.log('signin-onAuthStateChanged:' + tokenResult.claims)
          })
      }
    })
  },
  methods: {
    ...mapMutations('authentication', ['setUser']),
    ...mapActions('authentication', ['updateUserName']),

    async signinFB() {
      console.log('signin - signinFB')
      this.isLoading = true
      this.signinError = null
      const provider = new firebase.auth.FacebookAuthProvider()
      this.setUser(undefined)
      try {
        isDekstop()
          ? await firebase.auth().signInWithPopup(provider)
          : await firebase.auth().signInWithRedirect(provider)
      } catch (err) {
        this.signinError = err
        this.setUser(null)
      }
      this.isLoading = false
    },

    async signinEmail() {
      console.log('signin - signinEmail')
      this.signinError = null
      this.isLoading = true
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(
            this.signinForm.email,
            this.signinForm.password
          )
          .catch(err => {
            this.signinError = err
            this.setUser(null)
            console.log(err)
          })
      } catch (err) {
        this.signinError = err
        this.setUser(null)
      }
      this.isLoading = false
    },

    async signinGoogle() {
      console.log('signin - signinGoogle')
      this.signinError = null
      const provider = new firebase.auth.GoogleAuthProvider()
      this.setUser(undefined)

      try {
        isDekstop()
          ? await firebase.auth().signInWithPopup(provider)
          : await firebase.auth().signInWithRedirect(provider)
      } catch (err) {
        this.signinError = err
        this.setUser(null)
      }
    },
    toggleForm() {
      console.log('signin - toggleForm')
      this.errorMsg = ''
      this.showSigninForm = !this.showSigninForm
    },
    togglePasswordReset() {
      console.log('signin - togglePasswordReset')
      if (this.showForgotPassword) {
        this.showSigninForm = true
        this.showForgotPassword = false
        this.passwordResetSuccess = false
      } else {
        this.showSigninForm = false
        this.showForgotPassword = true
      }
    },
    resetPassword() {
      console.log('signin - resetPassword')
      this.performingRequest = true
      firebase
        .auth()
        .sendPasswordResetEmail(this.passwordForm.email)
        .then(() => {
          this.performingRequest = false
          this.passwordResetSuccess = true
          this.passwordForm.email = ''
          this.$router.push('/signin')
        })
        .catch(err => {
          console.log(err)
          this.performingRequest = false
          this.errorMsg = err.message
        })
    },

    async signupEmail() {
      console.log('signin - signupEmail')
      this.signinError = null
      //  try {
      //       const {user} = await firebase
      //           .auth()
      //           .createUserWithEmailAndPassword(this.signupForm.email, this.signupForm.password)
      //             .then((user) => {
      //                   // console.log("user.id: " + user.email)
      //                   this.$store.dispatch(
      //                   'authentication/updateUserName',
      //                   this.signupForm.name, user, {root:true})
      //             })
      //       // signout
      //       firebase
      //           .auth()
      //           .signOut()
      //           .then(user => {
      //               this.$router.push("/signin")
      //           })
      //   } catch (error) {
      //       console.log(error.message)
      //   }

      try {
        const { user } = await firebase
        // .auth()
        await firebase
          .auth()
          .createUserWithEmailAndPassword(
            this.signupForm.email,
            this.signupForm.password
          )
          .then(
            user => {
              return user.updateProfile({ displayName: this.signupForm.name })
            },
            error => {
              alert(error.message)
            }
          )
        // .then(user => {
        // console.log("user.id: " + user.uid)
        // const newUser = {
        //   // id: user.uid,
        //   name: this.signupForm.name,
        //   email: this.signupForm.email,
        // }
        // this.$store.dispatch(
        // 'authentication/updateUserName',
        // newUser, {root:true}
        // )
        this.$router.push('/myonboard')
        // })
        // .catch(err => {
        //   this.signinError = err
        //   this.setUser(null)
        //   console.log(err)
        // })
      } catch (err) {
        console.log(err)
        this.signinError = err
        this.setUser(null)
      }
    },
    doAjax() {
      this.isLoading = true
      // simulate AJAX
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
    onCancel() {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style lang="scss" scoped>
// @import '@/theme/variables.scss';
.v-container {
  padding: 0 !important;
}
.v-content {
  padding: 0 !important;
}
</style>
