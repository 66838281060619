<template>
  <v-container app fluid grid-list-md>
    <v-layout align-space-between justify-space-around row wrap grey darken-1>
        <v-flex d-flex xs12 sm6 md3 pt-3 pb-3>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <!-- <v-img :src="images.workshop" height="200px" /> -->
            <div style="text-align:center">
              <div class="display-1 purple">{{ $t('services.header2') }}</div>
                <div style="height:30px"></div>
              <p class="title white--text">{{ $t('services.sub21') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub211') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub212') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub213') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub214') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub22') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub221') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub222') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub223') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub224') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub23') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub231') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub232') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub233') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub234') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub24') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub241') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub242') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub243') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub244') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub25') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub251') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub252') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub253') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub254') }}</p>
                <div style="height:20px"></div>

            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0;
}
.v-content {
  padding: 0 !important;
  margin: 0 !important;
}
</style>