<template>
  <v-content>
    <section id="hero">
      <v-row no-gutters>
        <div style="height:60px"></div>
        <v-img :src="images.team" aspect-ratio="2.0" max-height="625" min-height="225">
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span
                    class="font-weight-black"
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2' 
                    ]"
                  >
                    SERVICES
                  </span>
                  <br />
                </v-col>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>
  </v-content>
</template>

<script>
export default {
  name: 'HomeImage',
  data() {
    return {
  images: {
    team: require('@/assets/it-team.png')
  }
    }
}
}
</script>

<style lang="scss" scoped>
// body {
//   margin: 0;
// }
// * {
//   margin: 0px;
// }
// .container {
//   padding: 20px !important;
// }
// .content {
//   padding: 20px !important;
// }

// .home-image {
//   background-image: linear-gradient(
//       -225deg,
//       rgba(255, 255, 255, 0.4) 0%,
//       rgba(0, 36, 61, 0.4) 50%
//     ),
//     url(../../assets/it-team.png);
//   background-size: contain;
//   background-position: top 60px right 0px;
//   // background-repeat: no-repeat;
//   background-attachment: fixed;
//   height: 40vmax;
//   opacity: 0.99;

  // background: linear-gradient(
  //     -225deg,
  //     rgba(255, 255, 255, 0.4) 0%,
  //     rgba(0, 36, 61, 0.4) 50%
  //   ),
  //   url(../../assets/img/ActionDay-group.jpg) 50% no-repeat;
  // background-size: cover;
  // height: 100vh;
  // text-align: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // background-image: linear-gradient(
  //     -225deg,
  //     rgba(255, 255, 255, 0.4) 0%,
  //     rgba(0, 36, 61, 0.4) 50%
  //   ),
  //   url(../../assets/img/ActionDay-group.jpg);

  // background-size: contain;
  // background-position: center center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // height: 606px;

  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // position: absolute;
  // width: 100%;
// }
</style>
