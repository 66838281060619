<template>
  <v-container app fluid grid-list-md>
    <v-layout align-space-between justify-space-around row wrap grey darken-1>
      <v-flex d-flex xs12 sm6 md3 pt-3 pb-3>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <!-- <v-img :src="images.education" height="200px" /> -->
            <div style="text-align:center">
              <div class="display-1 purple">{{ $t('services.header1') }}</div>
                <div style="height:30px"></div>

              <p class="title white--text">{{ $t('services.sub11') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub111') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub112') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub113') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub114') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub12') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub121') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub122') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub123') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub124') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub13') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub131') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub132') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub133') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub134') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub14') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub141') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub142') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub143') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub144') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub15') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub151') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub152') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub153') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub154') }}</p>
                <div style="height:20px"></div>
            </div>
          </div>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm6 md3 pt-3 pb-3>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <!-- <v-img :src="images.workshop" height="200px" /> -->
            <div style="text-align:center">
              <div class="display-1 purple">{{ $t('services.header2') }}</div>
                <div style="height:30px"></div>
              <p class="title white--text">{{ $t('services.sub21') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub211') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub212') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub213') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub214') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub22') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub221') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub222') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub223') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub224') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub23') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub231') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub232') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub233') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub234') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub24') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub241') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub242') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub243') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub244') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub25') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub251') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub252') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub253') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub254') }}</p>
                <div style="height:20px"></div>

            </div>
          </div>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm6 md3 pt-3 pb-3>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <!-- <v-img :src="images.other" height="200px" /> -->
            <div style="text-align:center">
              <div class="display-1 purple">{{ $t('services.header3') }}</div>
                <div style="height:30px"></div>

              <p class="title white--text">{{ $t('services.sub31') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub311') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub312') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub313') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub314') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub32') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub321') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub322') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub323') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub324') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub33') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub331') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub332') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub333') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub334') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub34') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub341') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub342') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub343') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub344') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub35') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub351') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub352') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub353') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub354') }}</p>
                <div style="height:20px"></div>
            </div>
          </div>
        </v-card>
      </v-flex>
      <v-flex d-flex xs12 sm6 md3 pt-3 pb-3>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <!-- <v-img :src="images.other" height="200px" /> -->
            <div style="text-align:center">
              <div class="display-1 purple">{{ $t('services.header4') }}</div>
                <div style="height:30px"></div>
              <p class="title white--text">{{ $t('services.sub41') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub411') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub412') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub413') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub414') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub42') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub421') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub422') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub423') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub424') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub43') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub431') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub432') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub433') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub434') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub44') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub441') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub442') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub443') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub444') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub45') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub451') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub452') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub453') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub454') }}</p>
                <div style="height:20px"></div>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0;
}
.v-content {
  padding: 0 !important;
  margin: 0 !important;
}
</style>