<template>
  <v-container app fluid grid-list-md>
    <v-layout align-space-between justify-space-around row wrap grey darken-1>
      <v-flex d-flex xs12 sm6 md3 pt-3 pb-3>
        <v-card flat color="grey darken-1" dark>
          <div style="text-align:center">
            <!-- <v-img :src="images.other" height="200px" /> -->
            <div style="text-align:center">
              <div class="display-1 purple">{{ $t('services.header3') }}</div>
                <div style="height:30px"></div>

              <p class="title white--text">{{ $t('services.sub31') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub311') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub312') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub313') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub314') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub32') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub321') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub322') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub323') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub324') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub33') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub331') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub332') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub333') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub334') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub34') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub341') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub342') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub343') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub344') }}</p>
                <div style="height:20px"></div>

              <p class="title white--text">{{ $t('services.sub35') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub351') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub352') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub353') }}</p>
                <p class="body-1 white--text">{{ $t('services.sub354') }}</p>
                <div style="height:20px"></div>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
}
.v-container {
  padding: 0 !important;
  margin: 0;
}
.v-content {
  padding: 0 !important;
  margin: 0 !important;
}
</style>